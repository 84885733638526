/* eslint-disable no-undef */
import { extend, localize } from 'vee-validate'
import ar from 'vee-validate/dist/locale/ar.json'
import en from 'vee-validate/dist/locale/en.json'
import fa from 'vee-validate/dist/locale/fa.json'
import {
  image as rule_image,
  size as rule_size,
  min_value as rule_min_value,
  is as is_value,
  numeric as is_numeric,
} from 'vee-validate/dist/rules'
import { toEnglishDigits } from '@/utils'

export const image = extend('image', rule_image)
export const size = extend('size', rule_size)
export const is = extend('is', is_value)
export const numeric = extend('numeric', is_numeric)
// eslint-disable-next-line camelcase
export const min_value = extend('min_value', rule_min_value)

export * from '@/@core/utils/validations/validations'
export const noZeroStater = extend('noZeroStater', {
  message: () => 'dontStartWithZero',
  validate: value => !/^0/.test(toEnglishDigits(value)),
})
export const nationalIdValidate = extend('nationalIdValidate', {

  validate: value =>
    // console.log(value)
    // console.log(toEnglishDigits(value))
    /^([0-9]){10}$/.test(toEnglishDigits(value)),
  message: () => 'nationalIdValidatemsg',
})
export const passportValidate = extend('passportValidate', {

  validate: value => /^(?!^0+$)[a-zA-Z0-9]{3,20}$/.test(toEnglishDigits(value)),
  message: () => 'passportValidatemsg',
})
export const notPersian = extend('notPersian', {
  validate: value => !/^[\u0600-\u06FF\s]+$/.test(value),
})
export const cryptoWalletAddress = extend('cryptoWalletAddress', {

  validate: (value, args) => {
    switch (args[0]) {
      case 'ETH':
        return /^(0x)[0-9A-Fa-f]{40}$/.test(value)

      case 'BSC':
        return /^(0x)[0-9A-Fa-f]{40}$/.test(value)

      case 'BNB':
        return /^(bnb1)[0-9a-z]{38}$/.test(value)

      case 'TRX':
        return /^T[1-9A-HJ-NP-Za-km-z]{33}$/.test(value)

      case 'BTC':
        return /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^[(bc1q)|(bc1p)][0-9A-Za-z]{37,62}$/.test(value)

      default:
        // throw new Error('symbol is not defined or its not acceptable')
        return true
    }
  },
  message: () => 'walletAddressIsIncorrect',

})

localize({
  en: {
    messages: { ...en.messages, notPersian: 'This field could not be persian/Arabic' },
    // eslint-disable-next-line no-dupe-keys
    messages: { ...en.messages },
  },
  ar: {
    messages: ar.messages,
  },
  fa: {
    messages: { ...fa.messages, notPersian: 'این فیلد نمیتواند فارسی یا عربی باشد' },
    // eslint-disable-next-line no-dupe-keys
    messages: { ...fa.messages },

  },

})
