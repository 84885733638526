// eslint-disable-next-line import/prefer-default-export
export function toEnglishDigits(str) {
  const e = '۰'.charCodeAt(0)
  const newSTR = str.toString().replace(/[۰-۹]/g, t => (t.charCodeAt(0) - e))
  return newSTR
}

// function that get number and comma separate it
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type: mimeString })
}

export function resizeImage(image, targetSize) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    let blob

    img.onload = async () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      let { width } = img
      let { height } = img

      let currentSize = image.size
      let scaleFactor = 1
      if (currentSize <= targetSize) {
        return resolve(image)
      }
      while (currentSize > targetSize) {
        scaleFactor = 0.9 // Adjust the scale factor as desired

        width *= scaleFactor
        height *= scaleFactor

        canvas.width = width
        canvas.height = height

        ctx.clearRect(0, 0, width, height)
        ctx.drawImage(img, 0, 0, width, height)

        const dataUrl = canvas.toDataURL('image/jpeg', 100)
        blob = dataURItoBlob(dataUrl)
        currentSize = blob.size
      }

      return resolve(blob)
    }

    img.onerror = error => {
      reject(error)
    }

    img.src = URL.createObjectURL(image)
  })
}

// function resizeImage(image, maxWidth) {
//   return new Promise((resolve, reject) => {
//     const img = new Image()

//     img.onload = () => {
//       let { width } = img
//       let { height } = img

//       if (width > maxWidth) {
//         const ratio = maxWidth / width
//         width = maxWidth
//         height = Math.round(height * ratio)
//       }

//       const canvas = document.createElement('canvas')
//       const ctx = canvas.getContext('2d')

//       canvas.width = width
//       canvas.height = height

//       ctx.drawImage(img, 0, 0, width, height)

//       canvas.toBlob(blob => {
//         resolve(blob)
//       }, 'image/jpeg')
//     }

//     img.onerror = error => {
//       reject(error)
//     }

//     img.src = URL.createObjectURL(image)
//   })
// }
